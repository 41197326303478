import img1 from "./img/1.jpg";
import img2 from "./img/2.jpg";
import img3 from "./img/3.jpg";
import img4 from "./img/4.jpg";
import img5 from "./img/5.jpg";
import img6 from "./img/6.jpg";
import img7 from "./img/7.jpg";
import img8 from "./img/8.jpg";
import img9 from "./img/9.jpg";
import img10 from "./img/10.jpg";
import img11 from "./img/11.jpg";
import img12 from "./img/12.jpg";
import img13 from "./img/13.jpg";
import img14 from "./img/14.jpg";
import img15 from "./img/15.jpg";
import img16 from "./img/16.jpg";
import img17 from "./img/17.jpg";
import img18 from "./img/18.jpg";
import img19 from "./img/19.jpg";
import img20 from "./img/20.jpg";

export default [
  { name: img1, sold: false},
  { name: img2, sold: false},
  { name: img3, sold: false},
  { name: img4, sold: true},
  { name: img5, sold: false},
  { name: img6, sold: false},
  { name: img7, sold: false},
  { name: img8, sold: false},
  { name: img9, sold: false},
  { name: img10, sold: false},
  { name: img11, sold: false},
  { name: img12, sold: true},
  { name: img13, sold: false},
  { name: img14, sold: false},
  { name: img15, sold: false},
  { name: img16, sold: true},
  { name: img17, sold: true},
  { name: img18, sold: true},
  { name: img19, sold: true},
  { name: img20, sold: true},

];
