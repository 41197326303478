import React, { Component } from "react";
import styled from "styled-components";

const VisImg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.4s ease-in;
  img {
    max-width: 80vw;
    max-height: 80vh;
  }
  p {
    position: absolute;
    bottom: 10px;
    right: 50px;
    text-align: right;
    color: #fff;
    margin: 0 20px;
    align-self: normal;
    font-size: 30px;
  }
`;
class VisibleImg extends Component {
  state = { image: {}, didMount: false };

  unmount() {
    this.setState({ didMount: false });
    setTimeout(() => {
      this.props.viewImg(null);
    }, 400);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ didMount: true });
    }, 0);
    document.addEventListener("keydown", event => {
      event.key === "Escape" && this.unmount();
    });
  }

  render() {
    const unmount = () => {
      this.setState({ didMount: false });
      setTimeout(() => {
        this.props.viewImg(null);
      }, 400);
    };

    const { didMount } = this.state;
    const { img } = this.props;
    return (
      <VisImg
        id={'visible-img'}
        className={`fade-in ${didMount && "visible"}`}
        onClick={() => unmount()}
      >
        {img && <img src={img.name} alt="" />}
        {img.sold && <p>verkauft</p>}
      </VisImg>
    );
  }
}

export default VisibleImg;
