import React, { Component } from "react";
import styled from "styled-components";
import Content from "./components/Content";
import images from "./Images";

const AppWrapper = styled.div`
  //font-family: "Merienda", cursive;

  font-family: "El Messiri", sans-serif;

  background-image: linear-gradient(
    to right top,
    #974574,
    #7d4071,
    #623c6b,
    #493662,
    #323055,
    #2d3c62,
    #27496e,
    #1f5577,
    #1b7c9e,
    #19a5c0,
    #32d0dc,
    #5ffbf1
  );
`;

class App extends Component {
  render() {
    return (
      <AppWrapper className="App">
        <Content images={images} />
      </AppWrapper>
    );
  }
}

export default App;
