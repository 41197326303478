import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.05);
    opacity: 0.6;
  }
`;

const Image = styled.img`
  min-height: 300px;
  max-width: 300px;
  max-width: 500px;
  max-height: 500px;
`;
const ImageContainer = props => {
  const { img, viewImg } = props;
  return (
    <Container onClick={() => viewImg(img)}>
      <Image src={img.name} alt="" />
    </Container>
  );
};

export default ImageContainer;
