import React, { Component } from "react";
import ImageContainer from "./ImageContainer";
import styled from "styled-components";
import VisibleImg from "./VisibleImg";
import Swipe from "react-easy-swipe";

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 30px;
  padding: 50px;
  position: relative;
  
  @media (max-width: 500px) {
   padding: 30px 0;
  }
  
  .fade-in.visible {
    opacity: 1;
  }
`;

class MyComponent extends Component {
  state = {
    visibleImage: null
  };

  viewImg(img) {
    this.setState({ visibleImage: img });
  }

  componentDidMount() {
    document.addEventListener("keydown", event =>
      this.skip({ event: event })
    )
  }

  skip({ event }) {
    const images = this.props.images;
    const img = this.state.visibleImage;
    const index = images.indexOf(img);

    event.key === "ArrowLeft" &&
      (index > 0
        ? this.setState({ visibleImage: images[index - 1] })
        : this.setState({ visibleImage: images[images.length - 1] }));
    event.key === "ArrowRight" &&
      (index < images.length - 1
        ? this.setState({ visibleImage: images[index + 1] })
        : this.setState({ visibleImage: images[0] }));
  }

  render() {
    const { images } = this.props;

    const onSwipeLeft = () => this.skip({ event: { key: "ArrowRight" } });
    const onSwipeRight = () => this.skip({ event: { key: "ArrowLeft" } });

    return (
      <ContentWrapper>
        {images.map(img => (
          <ImageContainer
            key={img.name}
            img={img}
            viewImg={this.viewImg.bind(this)}
          />
        ))}
        {this.state.visibleImage && (
          <Swipe onSwipeLeft={onSwipeLeft} onSwipeRight={onSwipeRight}>
            <VisibleImg
              img={this.state.visibleImage}
              viewImg={this.viewImg.bind(this)}
            />
          </Swipe>
        )}
      </ContentWrapper>
    );
  }
}

MyComponent.propTypes = {};

export default MyComponent;
